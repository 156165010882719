import { User } from 'oidc-client'
import { usePostLoginRouting } from 'pages/wizard/hooks/use-post-login-routing'
import { useNavigate } from 'react-router-dom'
import { LocalStore } from 'utils/utils'

export const useReturnUrl = () => {
	const navigate = useNavigate()
	const { route } = usePostLoginRouting()

	const handleReturnUrl = (user: User): User | undefined => {
		const prevRoute = LocalStore.get('prevRoute')

		const returnUrl: string | null = LocalStore.get('returnUrl')
		const isImpersonation: string | null = LocalStore.get('isImpersonation')
		const query: string | null = LocalStore.get('query')

		document.cookie = `loggedIn=true;expires=${user.expires_at};max=${user.expires_in}`
		let navigateUrl = '/home'
		if (returnUrl) {
			const updatedReturnUrl: string = returnUrl ?? '/home'

			if (isImpersonation === 'true') {
				navigateUrl = '/' + updatedReturnUrl
			} else if (updatedReturnUrl?.includes('http')) {
				window.location.href = updatedReturnUrl

				return user
			} else {
				navigateUrl = updatedReturnUrl
			}
		}

		if (prevRoute && !returnUrl) {
			navigateUrl = prevRoute as string
		} else if (!returnUrl) {
			navigateUrl = route
		}
		if (query) {
			return user
		}

		navigate(navigateUrl)
	}

	return {
		handleReturnUrl,
	}
}
