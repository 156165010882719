import { QueryClient } from '@tanstack/react-query'
import { queryClient } from 'config/tanstack-query'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Callback from 'pages/callback'
import FAQPage from 'pages/faq/faq'
import Saml from 'pages/sso/saml'
import { lazy } from 'react'
import { LoaderFunction, Navigate, NonIndexRouteObject, Outlet, Route, Routes } from 'react-router-dom'
import { retry } from 'utils/promise-rety'
import ProtectedRoute from './protected-route'
import PublicRoute from './public-route'
import { benefitRoutes, routesMap } from './routes-map'
import { preparePath } from './utils/prepareRoutes'

const Home = lazy(() => retry(() => import('pages/home')))
const Terms = lazy(() => retry(() => import('pages/terms')))
const DataUsePolicy = lazy(() => retry(() => import('pages/data-use-policy')))
const Register = lazy(() => retry(() => import('pages/register')))
const UnauthHome = lazy(() => retry(() => import('pages/unauth-home/layouts/UnauthHome')))
const UnauthLogin = lazy(() => retry(() => import('pages/unauth-home/layouts/UnauthLogin')))
const MyProfile = lazy(() => retry(() => import('pages/my-profile')))
const Search = lazy(() => retry(() => import('pages/search')))
const MyBenefits = lazy(() => retry(() => import('pages/my-benefits')))
const AutoquotesHome = lazy(() => retry(() => import('pages/autoquotes')))
const ForgotPassword = lazy(() => retry(() => import('pages/unauth-home/forgot-password/forgot-password')))
const BenefitCategories = lazy(() =>
	retry(() =>
		import('pages/benefit-categories/benefit-categories').then((module) => ({ default: module.BenefitCategories })),
	),
)
const DealsLandingPage = lazy(() => retry(() => import('pages/deals')))
const Plan = lazy(() => retry(() => import('pages/plan')))
const Wizard = lazy(() => retry(() => import('pages/wizard')))
const WorkerRedirect = lazy(() => retry(() => import('pages/worker-redirect')))
const Survey = lazy(() => retry(() => import('pages/survey')))
const ComingSoon = lazy(() => retry(() => import('pages/unauth-home/coming-soon/coming-soon')))
const Sandbox = lazy(() => retry(() => import('pages/sandbox/sandbox')))
const ExperimentRedirect = lazy(() => retry(() => import('./experimentRedirect')))
const Account = lazy(() => retry(() => import('pages/account/account-page')))

type CurriedLoaderFunction = (queryClient: QueryClient) => LoaderFunction<any>

const applyQueryClientToModule = (
	module: Omit<NonIndexRouteObject, 'loader'> & { loader?: CurriedLoaderFunction | undefined },
): any => {
	const curriedLoader = module.loader

	if (!curriedLoader) return module as NonIndexRouteObject

	const loader = curriedLoader(queryClient)

	return {
		...module,
		loader,
	} as NonIndexRouteObject
}

// a very clunky fallback-less flag guard
const FlagGuard = ({ flag, evaluate }: { flag: string; evaluate: (v: any) => boolean }) => {
	const { [flag]: flagValue } = useFlags()

	const result = evaluate(flagValue)

	if (result) {
		return <Outlet />
	}

	return null
}

/**
 * routes within this need to be relative instead of absolute
 *
 * If you have nested routes, you technically don't need to do path='parent/nested' you can do path='nested'
 *
 * If using tanstack-query in your loader: (THIS MAY GO AWAY IN RRv7 via the new context system)
 * - make a curried loader function that allows you to pass in queryClient
 * - manually apply queryClient via a chained like so:
 *     import('path').then((module) => ({ ...module, loader: module.loader(queryClient) }))
 */
export const DataLoaderCapableRoutes = (
	<Route>
		<Route path={preparePath(routesMap.missing)} lazy={() => import('pages/404')} />
		<Route element={<ProtectedRoute />} path={routesMap.deals._route}>
			<Route index element={<DealsLandingPage />} />
			<Route element={<FlagGuard flag='b4b_13018_access_travel_integration' evaluate={(v) => !!v} />}>
				<Route
					path={routesMap.deals.travel._route}
					lazy={() => import('pages/deals/travel').then((module) => applyQueryClientToModule(module))}
				/>
			</Route>
		</Route>
	</Route>
)

/**
 * Please follow new Route Component, loader, FallbackElement pattern instead of adding here
 *
 * These routes need migrated and will be migrated soon, so please don't add to our work.
 *
 * Questions? Read the docs here: https://reactrouter.com/en/main/route/lazy#lazy
 * PS - yes that's the lazy docs, read the example inside for how you can declare all route components in a single file
 *
 * Further questions - reach out to Colin to guide you through the process
 */
export const NeedingToBeMigratedRoutes = () => {
	const { und_6054_security_update, wrp2994 } = useFlags()

	return (
		<Routes>
			<Route element={<PublicRoute />}>
				{[routesMap.start, routesMap.error].map((path) => (
					<Route key={path} path={path} element={und_6054_security_update ? <UnauthLogin /> : <UnauthHome />} />
				))}
				<Route path={`${routesMap.login}/*`} element={<UnauthLogin />} />
				<Route path={routesMap.adminLogin} element={<UnauthLogin />} />
				<Route path={routesMap.forgotPassword} element={<ForgotPassword />} />
				<Route path={routesMap.register} element={<Register />} />
				<Route path={routesMap.comingSoon} element={<ComingSoon />} />
				<Route path={routesMap.logout} element={und_6054_security_update ? <UnauthLogin /> : <UnauthHome />} />
			</Route>
			<Route path={routesMap.faq} element={<FAQPage />} />
			<Route path={routesMap.terms} element={<Terms />} />
			<Route path={routesMap.callback} element={<Callback />} />
			<Route path={routesMap.dataUsePolicy} element={<DataUsePolicy />} />
			<Route path={routesMap.saml} element={<Saml />} />
			<Route path='sandbox' element={<Sandbox />} />
			<Route element={<ProtectedRoute />}>
				<Route path='/e' element={<ExperimentRedirect />} />
				<Route path={routesMap.enrollmentRedirect} element={<Navigate to={benefitRoutes.wizard.base} />} />
				<Route path='/enroll/*' element={<Navigate to={benefitRoutes.wizard.base} />} />
				<Route path={routesMap.home} element={<Home />} />
				<Route path={`${routesMap.account}/*`} element={<Account />} />
				<Route path={routesMap.myProfile} element={<MyProfile />} />
				<Route path={routesMap.search} element={<Search />} />
				<Route path={routesMap.myBenefits} element={<MyBenefits />} />
				<Route path={`${routesMap.autoQuote}/*`} element={<AutoquotesHome />} />
				<Route path={routesMap.workerRedirect} element={<WorkerRedirect />} />
				{wrp2994 && <Route path={routesMap.benefitCategory} element={<BenefitCategories />} />}
				<Route path='/' element={<Navigate to='/home' replace />} />
				<Route path={benefitRoutes.benefit._route} element={<Plan />} />
				<Route path={benefitRoutes.wizard._route} element={<Wizard />} />
				<Route path={routesMap.survey._route} element={<Survey />} />
			</Route>
		</Routes>
	)
}
