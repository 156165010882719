import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { benefitRoutes, routesMap } from 'routes/routes-map'

export const usePostLoginRouting = () => {
	const { b4b_15094_post_login_landing } = useFlags()

	const routingData = useMemo(() => {
		// Define a mapping object for flag values to routes
		const routeMapping = {
			'/wizard': benefitRoutes.wizard.base,
			'/wizard/intro': benefitRoutes.wizard.intro,
		}

		// Dynamically set the route based on the flag value
		const route =
			b4b_15094_post_login_landing in routeMapping ? routeMapping[b4b_15094_post_login_landing] : routesMap.home

		const hasWizardIntro = route === benefitRoutes.wizard.intro

		return { hasWizardIntro, route }
	}, [b4b_15094_post_login_landing])

	return routingData
}
