import { ProductRecommendation } from 'api/user-insights'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { ReactNode } from 'react'
import { IDLE } from 'types/status-types'

export type BenefitPlanStateRequestStates = {
	benefitPlansFetchStatus: StateMachineStatus
	oeWindowsFetchStatus: StateMachineStatus
	templateTokensFetchStatus: StateMachineStatus
	isbenefitPlanInitLoading: boolean
}

export type BenefitPlansState = {
	availablePlans: BenefitPlanV2[]
	calculatedTokens: Record<string, TemplateToken>
	currentOEWindows: OEWindow[]
	effectiveDates: Record<number, string>
	filterPlanIds: number[]
	isOE: boolean
	oeWindow: OEWindow | null | undefined
	oeWindows: OEWindow[]
	organizationName: string
	requestStates: BenefitPlanStateRequestStates
	b4b_pdpv3: boolean
}

export const initialState: BenefitPlansState = {
	availablePlans: [],
	b4b_pdpv3: false,
	calculatedTokens: {},
	currentOEWindows: [],
	effectiveDates: {},
	filterPlanIds: [],
	isOE: false,
	oeWindow: null,
	oeWindows: [],
	organizationName: '',
	requestStates: {
		benefitPlansFetchStatus: IDLE,
		isbenefitPlanInitLoading: true,
		oeWindowsFetchStatus: IDLE,
		templateTokensFetchStatus: IDLE,
	},
}
export interface EffectiveDatesResponse {
	benefitPlanEffectiveDates: Record<number, string>
}

export const NATIVE = 'Native'
export const NON_NATIVE = 'Non-native'

export type OEWindowPlan = {
	orderIndex: number
	useElectionsEffectiveDate: boolean
}
export interface OEWindow {
	createdDate: string
	displayEndDate: string
	electionsEffectiveDate: string
	employmentGroup: number
	employmentGroupName: string
	endDate: string
	oeWindowPlans: Record<number, OEWindowPlan>
	openEnrollmentWindowId: string
	openEnrollmentWindowName: string
	startDate: string
}

export interface OEWindowsResponse {
	items: Array<OEWindow>
	page: number
	totalResults: number
}

export interface UserOEWindowResponse {
	currentWindow: OEWindow
}

export type TemplateToken = {
	description: string
	isCalculated: boolean
	isMutable: boolean
	originalPropertyKey: string | null
	propertyType: string
	tokenRegex: RegExp
	tokenKey: string
	value?: string | number
}

export type ProductTemplate = {
	productTemplateId: string
	benefitProductId: string
	cardText: string
	cardTextHeadline: string
	createdDate: string
	createdUser: string
	ctaUrl: string
	enrollmentPageContent: string
	enrollmentPageFootnotes: string
	headerMedia?: string
	headerMediaIsVideo: boolean
	iPageContent: string
	modifiedDate: string
	modifiedUser: string
	productPageContent: string
	productUtilizationContent: string
	productTemplateDescription: string
	productTemplateName: string
	status: number
	termsAndConditionsText: string
}

type TokenKeyValue = {
	key: string
	value: string
}

export interface MarketingContent {
	benefitPlanMarketingContentId: string
	createdDate: string
	createdUser: string
	endDate: string
	ctaUrl: string
	modifiedDate: string
	modifiedUser: string
	productTemplate: ProductTemplate
	startDate: string
	tokens: TokenKeyValue[]
	versionName: string
}

export interface ModuleInstanceProperty {
	propertyName: string
	propertyValue: string
	propertyTypeId: number
	propertyTypeName: string
}

export interface ModuleInstance {
	moduleTemplateName: string
	order: number
	moduleInstanceProperties: ModuleInstanceProperty[]
}

export interface ModularMarketingContent {
	objectID: string
	productTemplateId: string
	benefitPlanMarketingContentId: string
	benefitPlanId: number
	organizationId: string
	startDate: number
	endDate: number
	productTemplateStatus: number
	productTemplateType: number
	cardText: string
	cardTextHeadline: string
	ctaUrl: string
	headerMediaIsVideo: boolean
	headerMedia?: string
	moduleInstances: ModuleInstance[]
	termsAndConditionsText: string
}

export interface BenefitPlanV2 {
	benefitPlanGroupParentId?: number
	benefitPlanId: number
	benefitPlanName: string
	benefitPlanType: string
	benefitProductId: string
	benefitProductCode: string
	benefitProductImage: string
	benefitProductName: string
	benefitProviderDescription: string
	benefitProviderName: string
	benefitProviderImage: string
	cancellationEvergreen: boolean
	cancellationOE: boolean
	cancellationQLE: boolean
	shouldIgnoreChildAge: boolean
	corestreamProductId: string
	ctaText: string
	effectiveMarketingContent: MarketingContent
	employerAccountCode: string
	employmentGroups: number[]
	employmentGroupNames: string[]
	enrollmentMethod: string
	enrollmentStatus?: EnrollmentStatus
	enrollmentImplementation: EnrollmentImplementation | null
	evidenceOfInsurabilitySubmissionLink?: string
	endDate: number
	grandfatheredPlanId?: number
	hasEmployeeTobaccoQuestion: boolean
	hasFamilyTobaccoQuestion: boolean
	hasKnockOutQuestions: boolean
	hasMultipleCoverageOptions: boolean
	hasSpouseTobaccoQuestion: boolean
	hasVisited: boolean
	isAvailableForLifeChangeEvent: boolean
	isAvailableForNewhires: boolean
	isEnrolled: boolean
	isEvergreen: boolean
	isIncludedInOE?: boolean
	isNative: boolean
	keyDate?: Date | string
	knockOutQuestionAnswer?: boolean
	knockOutQuestionClarifier: boolean
	knockOutQuestions: string
	lowestRate: string
	marketingContent: MarketingContent[]
	modularMarketingContent?: ModularMarketingContent
	maxChildAge?: number
	maxEmployeeAge: number
	maxSpouseAge?: number
	minEffectiveDate?: string
	minEmployeeAge: number
	minChildAge?: number
	minSpouseAge?: number
	nonNativeHeader?: string
	nonNativeCoverageHighlights?: string
	objectID: string
	organizationId: string
	parentPlanId?: number
	ppdsProductCode: string
	ppdsProviderCode: string
	programId: number
	rank: number
	requiredQuestions?: number[]
	shouldCollectBeneficiary: boolean
	shouldCollectDependents: boolean
	shouldCollectDependentSSN: boolean
	shouldCollectSSN: boolean
	shouldHideCTA: boolean
	shouldRequireEvidenceOfInsurability: boolean
	shouldRequireSpouseInfoForPricing: boolean
	startDate: number
	status: number //this may need to be a string in the future
	overriddenGrandfatherId?: number
	isOverriddenByParent?: boolean
	recommendation?: ProductRecommendation
}

export enum EnrollmentImplementation {
	nwPet = 'NW Pet',
	trustmark = 'Trustmark Simplink',
	liberty = 'Liberty Autoquoting',
	singleTier = 'Single-Tier',
}

export const NativeEnrollmentGuardImplementations = [
	EnrollmentImplementation.trustmark,
	EnrollmentImplementation.nwPet,
	EnrollmentImplementation.singleTier,
]

export type BenefitPlanCategory = {
	categoryName: string
	categoryDescription: string
	icon: string | ReactNode
	iconName: string
	isEnrolled: boolean
	rank: number
}

export const TokenContainers = [
	'enrollmentPageContent',
	'enrollmentPageFootnotes',
	'iPageContent',
	'termsAndConditionsText',
]

export interface SplitPlans {
	anytimePlans: BenefitPlanV2[]
	oePlans: BenefitPlanV2[]
	hasNativeInOe: boolean
}

export const AUTOHOME: string = 'auto & home insurance'
